import Header from "./Components/Header";
import Footer from "./Components/Footer";
import React, { useState, useEffect } from "react";

const AllBrand = () => {
  const [brandsData, setBrandsData] = useState({});
  const [brandsDataFiltered, setbrandsDataFiltered] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`https://saathie.com/api/get/brands`);
      const data = await response.json();
      setBrandsData(data.brands);
      setbrandsDataFiltered(data.brands);
      
    };
    fetchData();
  }, []);
  const handleChange = (e) => {

    if (
      e.target.value === "" ||
      e.target.value === null ||
      e.target.value === undefined
    ) {
    
      setbrandsDataFiltered(brandsData);
      return;
    }
    // filter nfts by name and description
    let filteredBrands = brandsData.filter((brand) => {
   
      return brand.name.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setbrandsDataFiltered(filteredBrands);
  };
  return (
    <>
      <Header />
      <main>
        <div className="hero_single inner_pages jarallax" data-jarallax>
          <img className="jarallax-img" src="/brand-banner.png" alt="" />
          <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.5)">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-9 col-lg-10 col-md-8">
                  <h1 className="">All Brands</h1>
                  <p className="">View All Brands on the NFTX platform.</p>
                </div>
              </div>
              {/* /row */}
            </div>
          </div>
          <div className="wave hero" />
        </div>

        <div
          id="jarallax-container-0"
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            zIndex: -100,
          }}
        >
          <img
            className="jarallax-img"
            src="img/hero_general.jpg"
            alt=""
            style={{
              objectFit: "cover",
              objectPosition: "50% 50%",
              maxWidth: "none",
              position: "fixed",
              top: "0px",
              left: "0px",
              width: "1434.4px",
              height: "919px",
              overflow: "hidden",
              pointerEvents: "none",
              transformStyle: "preserve-3d",
              backfaceVisibility: "hidden",
              willChange: "transform, opacity",
              marginTop: "199.5px",
              transform: "translate3d(0px, -199.5px, 0px)",
            }}
          />
        </div>

        <div className="filters_full">
          <div className="container clearfix">
            <div className="search_bar_list">
              <input
                type="text"
                className="form-control"
                placeholder="Search again..."
                onChange={handleChange}
              />
            </div>
            <a
              className="btn_search_mobile btn_filters"
              data-bs-toggle="collapse"
              href="#collapseSearch"
            >
              <i className="bi bi-search" />
            </a>
          </div>
          <div className="collapse" id="collapseSearch">
            <div className="search_bar_list">
              <input
                type="text"
                className="form-control"
                placeholder="Search again..."
                onChange={handleChange}
              />
            </div>
          </div>
          {/* /collapseSearch */}
        </div>
        {/* /filters_full */}
        <div className="container margin_30_250">
          <div className="page_header">
            <div className="breadcrumbs"></div>
            <h1>Results:</h1> <span>{brandsData && brandsData.length}</span>
          </div>
          <div className="row isotope-wrapper">
            {brandsData &&
              brandsData.length > 0 &&
              brandsDataFiltered.map((brand) => {
                return (
                  <>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 isotope-item art">
                      <div className="strip">
                        <figure>
                          <a
                            href={`/brand/${brand.slug}`}
                            className="btn_1 modal_popup"
                          >
                            View Projects
                          </a>
                          <img
                            src={`https://${brand.cid}.ipfs.w3s.link/${brand.logo}`}
                            className="lazy img-fluid"
                            alt=""
                          />
                        </figure>
                        <ul>
                          <li>
                            <a href={`/brand/${brand.slug}`} className="author">
                              <div className="author_thumb veryfied">
                                <i className="bi bi-check" />
                                <figure>
                                  <img
                                    src={`https://${brand.cid}.ipfs.w3s.link/${brand.logo}`}
                                    alt=""
                                    className="lazy"
                                    width={100}
                                    height={100}
                                  />
                                </figure>
                              </div>
                              <h6>@{brand.name.toLowerCase()}</h6>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          {/* /strip grid */}
        </div>
        {/* /container */}
      </main>
      <Footer />
    </>
  );
};

export default AllBrand;
