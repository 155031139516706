import Header from "./Components/Header";
import Footer from "./Components/Footer";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
const AllCollections = () => {
  const { category } = useParams();
  const [collectionData, setcollectionData] = useState({});
  const [collectionDataFiltered, setcollectionDataFiltered] = useState({});
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`https://saathie.com/api/get/all`);
      const data = await response.json();
      setcollectionData(data.projects);
      setcollectionDataFiltered(data.projects);
      setCategories(data.categories);
      setBrands(data.brands);
      if (category) {
        let filteredCollection = data.projects.filter((collection) => {

          return collection.category === category;
        });
        setcollectionDataFiltered(filteredCollection);
      }
    };
    fetchData();
  }, []);
  const handleChange = (e) => {
    if (
      e.target.value === "" ||
      e.target.value === null ||
      e.target.value === undefined
    ) {
      setcollectionDataFiltered(collectionData);
      return;
    }
    // filter nfts by name and description
    let filteredBrands = collectionData.filter((collection) => {

      return collection.name
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });

    setcollectionDataFiltered(filteredBrands);
  };
  const handleChangeCategory = (e) => {
    const selectedCategory = e.target.value;

    let filteredCollection = collectionData.filter((collection) => {
     
      return collection.category === selectedCategory;
    });

    setcollectionDataFiltered(filteredCollection);
  };
  const handleChangeBrand = (e) => {
    const selectedBrand = e.target.value;

    let filteredCollection = collectionData.filter((collection) => {

      return collection.brand === selectedBrand;
    });

    setcollectionDataFiltered(filteredCollection);
  };
  return (
    <>
      <Header />
      <main>
        <div className="hero_single inner_pages jarallax" data-jarallax>
          <img
            className="jarallax-img"
            src="/collections-banner.png"
            alt=""
          />
          <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.5)">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-9 col-lg-10 col-md-8">
                  <h1 className="">All Collections</h1>
                  <p className="">View All Collections on the NFTX platform.</p>
                </div>
              </div>
              {/* /row */}
            </div>
          </div>
          <div className="wave hero" />
        </div>
        <div className="filters_full">
          <div className="container">
            <div className="add_bottom_20 clearfix">
              <div className="custom_select"></div>
              <a
                href="#collapseFilters"
                data-bs-toggle="collapse"
                className="btn_filters"
              >
                <i className="bi bi-filter" />
                <span>Filters</span>
              </a>
              <div className="search_bar_list">
                <input
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                  placeholder="Search again..."
                />
              </div>
              <a
                className="btn_search_mobile btn_filters"
                data-bs-toggle="collapse"
                href="#collapseSearch"
              >
                <i className="bi bi-search" />
              </a>
            </div>
          </div>
          <div className="collapse filters_2" id="collapseFilters">
            <div className="container margin_detail">
              <div className="row">
                <div className="col-md-6">
                  <div className="filter_type">
                    <h6>Categories</h6>
                    <ul>
                      {categories &&
                        categories.length > 0 &&
                        categories.map((category) => (
                          <li>
                            <label className="container_check">
                              {category.name}
                              <input
                                type="checkbox"
                                value={category.name}
                                onChange={(e) => {
                                  handleChangeCategory(e);
                                }}
                              />
                              <span className="checkmark" />
                            </label>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="filter_type">
                    <h6>Brands</h6>
                    <ul>
                      {brands &&
                        brands.length > 0 &&
                        brands.map((brand) => (
                          <li>
                            <label className="container_check">
                              {brand.name}
                              <input
                                type="checkbox"
                                value={brand.name}
                                onChange={(e) => {
                                  handleChangeBrand(e);
                                }}
                              />

                              <span className="checkmark" />
                            </label>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* /row */}
            </div>
          </div>
          {/* /filters */}
          <div className="collapse" id="collapseSearch">
            <div className="search_bar_list">
              <input
                onChange={handleChange}
                type="text"
                className="form-control"
                placeholder="Search again..."
              />
            </div>
          </div>
          {/* /collapseSearch */}
        </div>

        <div className="container margin_30_250">
          <div className="page_header">
            <div className="breadcrumbs"></div>
            <h1>Results:</h1>{" "}
            <span>{collectionData && collectionDataFiltered.length}</span>
          </div>
          <div className="row isotope-wrapper">
            {collectionData &&
              collectionData.length > 0 &&
              collectionDataFiltered.map((collection) => {
                return (
                  <>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 isotope-item art">
                      <div className="strip">
                        <figure>
                          <a
                            href={`/collection/${collection.slug}`}
                            className="btn_1 modal_popup"
                          >
                            View Projects
                          </a>
                          <img
                            src={`https://${collection.cid}.ipfs.w3s.link/${collection.logo}`}
                            className="lazy img-fluid"
                            alt=""
                          />
                        </figure>
                        <ul>
                          <li>
                            <a
                              href={`/collection/${collection.slug}`}
                              className="author"
                            >
                              <div className="author_thumb veryfied">
                                <i className="bi bi-check" />
                                <figure>
                                  <img
                                    src={`https://${collection.cid}.ipfs.w3s.link/${collection.logo}`}
                                    alt=""
                                    className="lazy"
                                    width={100}
                                    height={100}
                                  />
                                </figure>
                              </div>
                              <h6>@{collection.name.toLowerCase()}</h6>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          {/* /strip grid */}
        </div>
        {/* /container */}
      </main>
      <Footer />
    </>
  );
};

export default AllCollections;
