import { ConnectWallet } from "@thirdweb-dev/react";

const Header = () => {
  return (
    <>
      <header 
      style={{
        marginBottom: "25px"
      }}
      className="header_in clearfix element_to_stick">
        <div className="layer" />
        {/* Opacity Mask Menu Mobile */}
        <div className="container">
          <div className="logo">
            <a href="/">
              <img
                src="https://static.tildacdn.com/tild3463-3261-4166-a638-636338333231/Frame_2.png"
                alt=""
                width={100}
                height={50}
                className="dark"
              />
            </a>
          </div>
          <ul className="top_menu">
            <li>
              <ConnectWallet className="btn_access" modalSize="compact" modalTitleIconUrl={""}  />
            </li>
          </ul>
          {/* /top_menu */}
          <a href="#0" className="open_close">
            <i className="bi bi-list" />
            <span>Menu</span>
          </a>
          <nav className="main-menu">
            <div id="header_menu">
              <a href="#0" className="open_close">
                <i className="bi bi-x" />
              </a>
              <a href="/" className="logo_menu">
                <img
                  src="https://static.tildacdn.com/tild3463-3261-4166-a638-636338333231/Frame_2.png"
                  alt=""
                  width={120}
                  height={30}
                  className="dark lazy"
                />
              </a>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};
export default Header;
