import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { useParams } from "react-router-dom";
import { useContract, useActiveListings } from "@thirdweb-dev/react";

import React, { useState, useEffect } from "react";
const CollectionDetailPage = () => {
  const [state, setState] = useState({});
  const [collection, setCollection] = useState([]);
  const [filteredNfts, sefilteredNfts] = useState([]);
  const { collectionslug } = useParams();
  const { contract } = useContract(
    process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS,
    "marketplace"
  );

  let {
    data: directListings,
    isLoading,
    error,
  } = useActiveListings(contract, {
    tokenContract: collection.blockchainContract,
  });

  const handleChangeFilters = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    const fetchCollection = async () => {
      fetch(`https://saathie.com/api/get/project/${collectionslug}`)
        .then((res) => res.json())
        .then((data) => {
          setCollection(data.project);
        });
    };
    fetchCollection();
    if (directListings) {
      sefilteredNfts(directListings);
      let indexOfLowestPrice;
      let listed;
      if (directListings.length > 0) {
    
        indexOfLowestPrice = directListings.reduce((acc, curr, index) => {
          if (curr.buyoutCurrencyValuePerToken.displayValue < acc) {
            return curr.buyoutCurrencyValuePerToken.displayValue;
          } else {
            return acc;
          }
        }, directListings[0].buyoutCurrencyValuePerToken.displayValue);

        listed = directListings.length;
      }
      setState((prevState) => ({
        ...prevState,
        lowestPrice: indexOfLowestPrice,
        listed,
      }));
    }
  }, [directListings]);
  const handleCheck = (e) => {
    
    // filter nfts by name and description
    directListings = directListings.filter((nft) => {
      
      return (
        nft.asset.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        nft.asset.description
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
    });
    sefilteredNfts(directListings);
  };
  const handleFilter = (e) => {
    let newlistings = [];
    directListings.forEach((listing) => {
      
      if (
        Math.floor(listing.buyoutCurrencyValuePerToken.displayValue) >=
          state.from &&
        Math.floor(listing.buyoutCurrencyValuePerToken.displayValue) <= state.to
      ) {
        newlistings.push(listing);
      }
    });
    sefilteredNfts(newlistings);
  };

  return (
    <>
      <Header />
      <main>
        <div
          className="hero_single inner_pages author_page jarallax"
          data-jarallax
        >
          <img
            className="jarallax-img"
            src={`https://${collection && collection.cid}.ipfs.w3s.link/${
              collection && collection.banner
            }`}
            alt=""
          />
          <div
            className="opacity-mask"
            data-opacity-mask="rgba(0, 0, 0, 0.4)"
          />
          <div className="wave hero" />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 pt-lg-5">
              <h1 className="slide-animated one is-transitioned">
                <img
                  src={`https://${collection && collection.cid}.ipfs.w3s.link/${
                    collection && collection.logo
                  }`}
                  alt=""
                  width={100}
                />
              </h1>
              <h2 className="slide-animated two is-transitioned">
                {collection.name}
              </h2>
              <p
                style={{
                  marginBottom: "0px!important",
                }}
                className="slide-animated two is-transitioned"
              >
                {collection.description}
              </p>
              <div className="count slide-animated three is-transitioned">
                <ul>
                  <li>
                    <h5>{Math.floor(state.lowestPrice) || 0}</h5>Floor Price
                  </li>
                  <li>
                    <h5>{state.listed || 0}</h5> Listings
                  </li>
                </ul>
              </div>
            </div>
          </div>{" "}
        </div>
        <div className="filters_full version_2">
          <div className="container clearfix">
            <div className="pb-3 clearfix">
              <a href="#0" className="open_filters btn_filters">
                <i className="bi bi-filter" />
                <span>Filters</span>
              </a>
              <div className="search_bar_list">
                <input
                  onChange={(e) => handleCheck(e)}
                  type="text"
                  className="form-control"
                  placeholder="Search again..."
                />
              </div>
              <a
                className="btn_search_mobile btn_filters"
                data-bs-toggle="collapse"
                href="#collapseSearch"
              >
                <i className="bi bi-search" />
              </a>
            </div>
          </div>
          <div className="collapse" id="collapseSearch">
            <div className="search_bar_list">
              <input
                onChange={(e) => handleCheck(e)}
                type="text"
                className="form-control"
                placeholder="Search"
              />
            </div>
          </div>
          {/* /collapseSearch */}
        </div>
        {/* /filters_full */}
        <div className="container">
          <div className="page_header">
            <div className="breadcrumbs">
              <ul>
                <li>
                  <a href="/collections">Collections</a>
                </li>

                <li>{collection.name}</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <aside className="col-lg-3" id="sidebar_fixed">
              <div className="filter_col">
                <div className="inner_bt">
                  <a href="#" className="open_filters">
                    <i className="bi bi-x" />
                  </a>
                </div>

                {/* /filter_type */}
                <div className="filter_type">
                  <h4>
                    <a
                      href="#filter_3"
                      data-bs-toggle="collapse"
                      className="opened"
                    >
                      Price
                    </a>
                  </h4>
                  <div className="collapse show" id="filter_3">
                    <div className="range_input">Price range</div>
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          onChange={(e) => handleChangeFilters(e)}
                          type="text"
                          className="form-control"
                          placeholder="from"
                          name="from"
                        />
                      </div>
                      <div className="mb-4 col-lg-6">
                        <input
                          onChange={(e) => handleChangeFilters(e)}
                          type="text"
                          className="form-control"
                          placeholder="to"
                          to="to"
                          name="to"
                        />
                      </div>{" "}
                    </div>
                  </div>
                </div>

                <div className="buttons">
                  <a
                    onClick={() => handleFilter()}
                    className="btn_1 full-width outline"
                  >
                    Filter
                  </a>
                </div>
              </div>
            </aside>
            {collection && (
              <div className="col-lg-9">
                <div className="row">
                  {isLoading && <div data-loader="circle-side"></div>}
                  {directListings &&
                    filteredNfts.map((listing) => {
                      return (
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                          <div className="strip">
                            <figure>
                              <a href={`/listing/${listing.id}`} className="btn_1 modal_popup">Buy Now</a>
                              <img
                                src={listing.asset.image}
                                className="lazy"
                                alt=""
                                width={533}
                                height={400}
                              />
                              <a className="strip_info">
                                <div className="item_title">
                                  <h3>{listing.asset.name}</h3>
                                  <small>
                                    {Math.floor(
                                      listing.buyoutCurrencyValuePerToken
                                        .displayValue
                                    )}{" "}
                                    Matic{" "}
                                  </small>
                                </div>
                              </a>
                            </figure>
                            <ul>
                              <a
                                href={`/listing/${listing.id}`}
                                className="btn_1 modal_popup"
                              >
                                Buy Now
                              </a>
                            </ul>
                          </div>
                        </div>
                      );
                    })}
                  {/* /strip grid */}
                </div>
                {/* <div className="pagination_fg mb-4">
                  <a href="#">«</a>
                  <a href="#" className="active">
                    1
                  </a>
                  <a href="#">2</a>
                  <a href="#">3</a>
                  <a href="#">4</a>
                  <a href="#">5</a>
                  <a href="#">»</a>
                </div> */}
              </div>
            )}
            {/* /col */}
          </div>
        </div>
        {/* /container */}
      </main>
      <Footer />
    </>
  );
};
export default CollectionDetailPage;
