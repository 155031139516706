import { Route, BrowserRouter, Routes } from "react-router-dom";
import React from "react";
import Home from "./Pages/Home";
import NFTSingleListing from "./Pages/NFTSingleListing";
import BrandDetailPage from "./Pages/BrandDetailPage";
import CollectionDetailPage from "./Pages/CollectionDetailPage";
import AllBrand from "./Pages/AllBrand";
import AllCategory from "./Pages/AllCategory";
import AllCollections from "./Pages/AllCollections";
import "./App.css";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/brands" element={<AllBrand />} />
          <Route path="/categories" element={<AllCategory />} />
          <Route path="/collections/:category?" element={<AllCollections />} />
          <Route path="/listing/:listingId" element={<NFTSingleListing />} />
          <Route path="/brand/:brandslug" element={<BrandDetailPage />} />
          <Route
            path="/collection/:collectionslug"
            element={<CollectionDetailPage />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
