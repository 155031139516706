import Header from "./Components/Header";
import Footer from "./Components/Footer";
import React, { useState, useEffect } from "react";

const AllCategory = () => {
  const [CategoryData, setCategoryData] = useState({});
  const [CategoryDataFiltered, setCategoryDataFiltered] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`https://saathie.com/api/get/categories`);
      const data = await response.json();

      setCategoryData(data.categories);
      setCategoryDataFiltered(data.categories);
     
    };
    fetchData();
  }, []);
  const handleChange = (e) => {

    if (
      e.target.value === "" ||
      e.target.value === null ||
      e.target.value === undefined
    ) {
      setCategoryDataFiltered(CategoryData);
      return;
    }
    // filter nfts by name and description
    let filteredCategories = CategoryData.filter((category) => {
    
      return category.name.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setCategoryDataFiltered(filteredCategories);
  };
  return (
    <>
      <Header />
      <div className="hero_single inner_pages jarallax" data-jarallax>
        <img
          className="jarallax-img"
          src="/banner-category.png"
          alt=""
        />
        <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.5)">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-10 col-md-8">
                <h1 className="">All Categories</h1>
                <p className="">View All Categories on the NFTX platform.</p>
              </div>
            </div>
            {/* /row */}
          </div>
        </div>
        <div className="wave hero" />
      </div>
      <main>
        <div className="filters_full">
          <div className="container clearfix">
            <div className="search_bar_list">
              <input
                type="text"
                className="form-control"
                placeholder="Search again..."
                onChange={handleChange}
              />
            </div>
            <a
              className="btn_search_mobile btn_filters"
              data-bs-toggle="collapse"
              href="#collapseSearch"
            >
              <i className="bi bi-search" />
            </a>
          </div>
          <div className="collapse" id="collapseSearch">
            <div className="search_bar_list">
              <input
                type="text"
                className="form-control"
                placeholder="Search again..."
                onChange={handleChange}
              />
            </div>
          </div>
          {/* /collapseSearch */}
        </div>
        {/* /hero_single */}
        <div className="container margin_90_60">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {CategoryData &&
                  CategoryData.length > 0 &&
                  CategoryDataFiltered.map((category) => {
                    return (
                      <div className="col-md-3">
                        <article className="blog">
                          <figure>
                            <a href={`/collections/${category.name}`}>
                              <img
                                src={`https://${category.cid}.ipfs.w3s.link/${category.logo}`}
                                height={200}
                                width={200}
                              />
                            </a>
                          </figure>
                          <div className="post_info">
                            <h2>
                              <a href={`/collections/${category.name}`}>
                                {category.name}
                              </a>
                            </h2>
                            {/* <p>{category.description}</p> */}
                          </div>
                        </article>
                        {/* /article */}
                      </div>
                    );
                  })}

                {/* /col */}
              </div>
              {/* /row */}

              {/* /pagination */}
            </div>
            {/* /col */}
          </div>
          {/* /row */}
        </div>
        {/* /container */}
      </main>
      <Footer />
    </>
  );
};

export default AllCategory;
