import React, { useEffect, useState } from "react";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
const Home = () => {
  const [data, setData] = useState([]);
  const [active, setActive] = useState(0);
  useEffect(() => {
    async function fetchData() {
     
      await fetch("https://saathie.com/api/get/all").then(async (res) => {
        let data1 = await res.json();
       
        setData(data1);
      });
    }
    fetchData();
  }, []);
  if (data.length === 0) return null;
  return (
    <>
      <Header />
      <main>
        <div
          className="hero_single version_2 jarallax"
          data-jarallax-video="mp4:./video/intro_blue.mp4,webm:./video/intro_blue.webm,ogv:./video/intro_blue.ogv"
        >
          <div className="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.5)">
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-lg-7">
                  <h1>
                    Discover, collect,
                    <br />
                    and sell extraordinary NFTs
                  </h1>
                  <p>on the world's first &amp; largest NFT marketplace</p>
                  <form method="post" action="grid-listing-1.html">
                    <div className="row g-0 custom-search-input mx-auto">
                      <div className="col-md-9">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Search item..."
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <input type="submit" defaultValue="Find" />
                      </div>
                    </div>
                    {/* /row */}
                    <div className="search_trends">
                      <h5>Trending:</h5>
                      <ul>
                        <li>
                          <a href="#0">Real Estate</a>
                        </li>
                        <li>
                          <a href="#0">Liquor</a>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
              {/* /row */}
            </div>
          </div>
          <div className="wave hero" />
        </div>

        {/* /hero_single video bg jarallax*/}
        <div className="main_categories">
          <div className="container">
            <ul className="clearfix">
              {data &&
                data.categories &&
                data.categories.length > 0 &&
                data.categories.map((category, index) => {
                  return (
                    <li>
                      <a href={`/collections/${category.name}`}>
                        <img
                          src={`https://${category.cid}.ipfs.w3s.link/${category.logo}`}
                          height={40}
                          width={40}
                          style={{
                            marginBottom: 10,
                          }}
                        />
                        <h3>{category.name}</h3>
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
          {/* /container */}
        </div>

        {/* /container */}
        <div className="bg_gray">
          <div className="container margin_120_90">
            <div className="main_title version_2">
              <span>
                <em />
              </span>
              <h2>Top Brands</h2>
              <p>Top Brands on NFTX.</p>
              <a href="/brands">
                View All <i className="bi bi-arrow-right" />
              </a>
            </div>
            {/* /main_title */}
            <div className="row author_list">
              {data &&
                data.brands &&
                data.brands.length > 0 &&
                data.brands.map((brand, index) => {
                  return (
                    <div className="col-lg-4 col-md-6">
                      <a href={`/brand/${brand.slug}`} className="author">
                        <div className="author_thumb veryfied">
                          <figure>
                            <img
                              src={`https://${brand.cid}.ipfs.w3s.link/${brand.logo}`}
                              alt=""
                              className="lazy"
                              width={100}
                              height={100}
                            />
                          </figure>
                        </div>
                        <div>
                          <h6>{brand.name}</h6>
                        </div>
                      </a>
                    </div>
                  );
                })}
            </div>
            {/* /row */}

            {/* /banner */}
          </div>
          {/* /container */}
        </div>
        {/* /bg_gray */}
        <div className="container margin_120_90">
          <div className="main_title version_2">
            <span>
              <em />
            </span>
            <h2>Latest Collections</h2>
            <p>Latest collections on NFT x</p>
            <a href="/collections">
              View All <i className="bi bi-arrow-right" />
            </a>
          </div>
          {/* /main_title */}
          <div className="row justify-content-center">
            {data &&
              data.projects &&
              data.projects.length > 0 &&
              data.projects.map((project, index) => {
                return (
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                    <div className="strip">
                      <figure>
                        <img
                          src={`https://${project.cid}.ipfs.w3s.link/${project.logo}`}
                          className="lazy"
                          alt=""
                          width={533}
                          height={400}
                        />
                        <a
                          href={`/collection/${project.slug}`}
                          className="strip_info"
                        >
                          <div className="item_title">
                            <h3>{project.name}</h3>
                          </div>
                        </a>
                      </figure>
                      <ul>
                        <li>
                          <a
                            href={`/collection/${project.slug}`}
                            className="author"
                          >
                            <div className="author_thumb"></div>
                            <h6>@{project.slug}</h6>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            {/* /strip grid */}
          </div>
          {/* /row */}
        </div>
        {/* /container */}
        <div className="bg_gray">
          <div className="container margin_120_90">
            <div className="main_title center mb-5">
              <span>
                <em />
              </span>
              <h2>Create and sell your NFTs</h2>
              <p>Cum doctus civibus efficiantur in imperdiet deterruisset.</p>
            </div>
            <div className="row justify-content-md-center how_2">
              <div className="col-lg-5 text-center">
                <figure className="mb-5">
                  <img
                    src="/box.png"
                    data-src="img/web_wireframe.svg"
                    alt=""
                    className="img-fluid lazy"
                    width={360}
                    height={380}
                  />
                </figure>
              </div>
              <div className="col-lg-5">
                <ul>
                  <li>
                    <h3>
                      <span>#01.</span> Set up your wallet
                    </h3>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque.
                    </p>
                  </li>
                  <li>
                    <h3>
                      <span>#02.</span> Add your NFT's
                    </h3>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque.
                    </p>
                  </li>
                  <li>
                    <h3>
                      <span>#03.</span> Sell your NFT's
                    </h3>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque.
                    </p>
                  </li>
                </ul>
              </div>
              {/* /row */}
            </div>
          </div>
          {/* /container */}
        </div>

        {/* /bg_gray */}
        <div className="container margin_120_90">
          
          {/* /main_title */}

          <div
            className="banner mt-5 lazy"
            data-bg="url(img/banner_bg.jpg)"
            data-cue="slideInUp"
            data-was-processed="true"
            style={{
              backgroundImage: 'url("img/banner_bg.jpg")',
              animationName: "slideInUp",
              animationDuration: "300ms",
              animationTimingFunction: "ease",
              animationDelay: "0ms",
              animationDirection: "normal",
              animationFillMode: "both",
            }}
            data-show="true"
          >
            <div
              className="d-flex align-items-center opacity-mask justify-content-between p-5"
              data-opacity-mask="rgba(0, 0, 0, 0.2)"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
            >
              <div>
                <small>Join the Community</small>
                <h3>Become a Creator</h3>
                <p>Start selling your artworks and gain huge profits!</p>
              </div>
              <div>
                <a href="become-author.html" className="btn_1 medium pulse_bt">
                  Join Now
                </a>
              </div>
            </div>
            {/* /wrapper */}
          </div>

          {/* /row */}
        </div>

        {/* /container */}
      </main>
      <Footer />
    </>
  );
};

export default Home;
