import Header from "./Components/Header";
import {
  useContract,
  useListing,
  useAddress,
  useNetworkMismatch,
  ChainId,
  useNetwork,
  useMetamask,
  useBuyNow,
  Web3Button,
} from "@thirdweb-dev/react";
import Footer from "./Components/Footer";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ListingType } from "@thirdweb-dev/sdk";

const NFTSingleListing = () => {
  const [loading, setLoading] = useState(false);
  let [maticPrice, setMaticPrice] = useState(0);
  let [projectDetails, setProjectDetails] = useState({});
  const misMatch = useNetworkMismatch();
  const [, setNetwork] = useNetwork();
  const address = useAddress();
  const connectWithMetamask = useMetamask();
  const { contract: contract, isLoading: loadingContract } = useContract(
    process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS,
    "marketplace"
  );
  const { mutateAsync: buyNow, isLoading1, error1 } = useBuyNow(contract);
  const { listingId } = useParams();
  const {
    data: directListing,
    isLoading,
    error,
  } = useListing(contract, listingId);

  useEffect(() => {
    async function getMaticPrice() {
      const response = await fetch(
        `https://api.coingecko.com/api/v3/coins/matic-network`
      );
      const data = await response.json();
      setMaticPrice(data.market_data.current_price.usd);
    }
    async function getProject() {
      if (!directListing) {
      } else {
        const response = await fetch(
          `https://saathie.com/api/get/projects/address/${directListing.assetContractAddress}`
        );
        const data = await response.json();

        setProjectDetails(data);
      }
    }
    getProject();
    getMaticPrice();
  }, [directListing]);
  if (!contract || isLoading)
    return (
      <div id="preloader">
        <div data-loader="circle-side"></div>
      </div>
    );
  return (
    <>
      <Header />
      <div className="container margin_60_250">
        <div className="row">
          <div className="col-xl-7 col-lg-7">
            <figure>
              <img
                src={directListing && directListing.asset.image}
                alt=""
                className="img-fluid rounded"
              />
            </figure>
          </div>
          {/* /col */}
          <div className="col-xl-5 col-lg-5">
            <div className="detail_col">
              <div className="main_info clearfix mb-1">
                <div className="item_desc">
                  <div className="mb-3">
                    <a
                      href={
                        directListing &&
                        projectDetails.brand &&
                        `/brand/${projectDetails.brand.slug}`
                      }
                      className="author"
                    >
                      <div className="author_thumb veryfied">
                        <i className="bi bi-check" />
                        <figure>
                          <img
                            src={`https://${
                              projectDetails.brand && projectDetails.brand.cid
                            }.ipfs.w3s.link/${
                              projectDetails.brand && projectDetails.brand.logo
                            }`}
                            className="lazy loaded"
                            width={100}
                            height={100}
                            id="brand-logo"
                            data-was-processed="true"
                          />
                        </figure>
                      </div>
                      <h6 className="ms-1">
                        <span>Creator</span>
                        {directListing &&
                          projectDetails.brand &&
                          projectDetails.brand.name}
                      </h6>
                    </a>
                  </div>
                </div>
                <div className="score_in">
                  <div className="mb-3">
                    <a
                      href={
                        directListing &&
                        projectDetails.brand &&
                        `/collection/${projectDetails.projects[0].slug}`
                      }
                      className="author"
                    >
                      <div className="author_thumb veryfied">
                        <i className="bi bi-check" />
                        <figure>
                          <img
                            src={`https://${
                              projectDetails.projects &&
                              projectDetails.projects[0].cid
                            }.ipfs.w3s.link/${
                              projectDetails.projects &&
                              projectDetails.projects[0].logo
                            }`}
                            className="lazy loaded"
                            width={100}
                            height={100}
                            id="brand-logo"
                            data-was-processed="true"
                          />
                        </figure>
                      </div>
                      <h6 className="">
                        <span>Collection</span>
                        {directListing &&
                          projectDetails.projects &&
                          projectDetails.projects[0].name}
                      </h6>
                    </a>
                  </div>
                </div>
              </div>
              <hr />
              <h1 className="mb-md-2">
                {directListing && directListing.asset.name}
              </h1>
              <p>{directListing && directListing.asset.description}</p>
              <p className="item_price clearfix">
                <span>
                  <strong>
                    {directListing &&
                      directListing.buyoutCurrencyValuePerToken
                        .displayValue}{" "}
                    {directListing &&
                      directListing.buyoutCurrencyValuePerToken.symbol}
                  </strong>{" "}
                  ($
                  {directListing &&
                    directListing.buyoutCurrencyValuePerToken.displayValue *
                      maticPrice.toLocaleString()}
                  )
                </span>{" "}
                <Web3Button
                  contractAddress={"0x862B968fB699eC4E58f19A6520edeD8Bfb3d0e5c"}
                  action={() =>
                    buyNow({
                      id: listingId, // ID of the listing to buy
                      type: ListingType.Direct, // Direct (0) or Auction (1)
                      buyAmount: 1,
                    })
                  }
                  onError={(error) => alert("Something Went Wrong")}
                  onSuccess={(result) => alert("Purchase Successful!")}
                  className="btn_1 modal_popup"
                >
                  Buy Now
                </Web3Button>
              </p>
              <p>
                Project Name:{" "}
                {projectDetails &&
                  projectDetails.projects &&
                  projectDetails.projects[0].name}
                <br /> <br />
                Project Description: <br />
                {projectDetails &&
                  projectDetails.projects &&
                  projectDetails.projects[0].description.slice(0, 500)}
              </p>
              {/* /tabs_detail */}
            </div>

            {/* /detail_col */}
          </div>
          {/* /col */}
        </div>
        <div className="tabs_detail">
          <ul className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <a
                id="tab-A"
                href="#pane-A"
                className="nav-link active"
                data-bs-toggle="tab"
                role="tab"
              >
                Project History
              </a>
            </li>

            <li className="nav-item">
              <a
                id="tab-C"
                href="#pane-C"
                className="nav-link"
                data-bs-toggle="tab"
                role="tab"
              >
                Details
              </a>
            </li>
          </ul>
          <div className="tab-content" role="tablist">
            <div
              id="pane-A"
              className="card tab-pane fade show active"
              role="tabpanel"
              aria-labelledby="tab-A"
            >
              <div className="card-header" role="tab" id="heading-A">
                <h5>
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse-A"
                  >
                    Project History
                  </a>
                </h5>
              </div>
              <div
                id="collapse-A"
                className="collapse"
                role="tabpanel"
                aria-labelledby="heading-A"
              >
                <div className="pt-4">
                  <p
                    style={{
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                      alignContent: "left!important",
                      display: "flex",
                    }}
                  >
                    {directListing &&
                      projectDetails.brand &&
                      projectDetails.projects[0].history}{" "}
                  </p>

                  <a
                    className="btn_1"
                    id="read-more"
                    href={`https://${
                      projectDetails.projects && projectDetails.projects[0].cid
                    }.ipfs.w3s.link/${
                      projectDetails.projects &&
                      projectDetails.projects[0].whitepaper
                    }`}
                    target="_blank"
                  >
                    Whitepaper
                  </a>

                  <a
                    style={{
                      marginLeft: "10px",
                    }}
                    target="_blank"
                    className="btn_1"
                    id="read-more"
                    href={`https://${
                      projectDetails.projects && projectDetails.projects[0].cid
                    }.ipfs.w3s.link/${
                      projectDetails.projects &&
                      projectDetails.projects[0].brouchure
                    }`}
                  >
                    Brouchre
                  </a>
                </div>
              </div>
            </div>
            {/* /tab */}
            <div
              id="pane-B"
              className="card tab-pane fade"
              role="tabpanel"
              aria-labelledby="tab-B"
            >
              <div className="card-header" role="tab" id="heading-B">
                <h5>
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse-B"
                  >
                    Images
                  </a>
                </h5>
              </div>
            </div>
            {/* /tab */}
            <div
              id="pane-C"
              className="card tab-pane fade"
              role="tabpanel"
              aria-labelledby="tab-C"
            >
              <div className="card-header" role="tab" id="heading-C">
                <h5>
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse-C"
                  >
                    Additional info
                  </a>
                </h5>
              </div>
              <div
                id="collapse-C"
                className="collapse"
                role="tabpanel"
                aria-labelledby="heading-C"
              >
                <div className="pt-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <ul className="bullets">
                        <li>
                          NFT Token ID{" "}
                          <span>{directListing && directListing.asset.id}</span>
                        </li>
                        <li>
                          Project Blockchain Contract{" "}
                          <span>
                            {directListing &&
                              directListing.assetContractAddress}
                          </span>
                        </li>

                        <li>
                          Project Blockchain <span>Matic</span>
                        </li>
                        <li>
                          Blockchain CID (storage){" "}
                          <span>
                            <a
                              href={`https://${
                                projectDetails.projects &&
                                projectDetails.projects[0].cid
                              }.ipfs.w3s.link`}
                              target="_blank"
                            >
                              {projectDetails.projects &&
                                projectDetails.projects[0].cid}
                            </a>
                          </span>
                        </li>

                        <li>
                          Whitepaper Blockchain Link{" "}
                          <span>
                            <a
                              href={`https://${
                                projectDetails.projects &&
                                projectDetails.projects[0].cid
                              }.ipfs.w3s.link/${
                                projectDetails.projects &&
                                projectDetails.projects[0].whitepaper
                              }`}
                              target="_blank"
                            >
                              Link
                            </a>
                          </span>
                        </li>
                        <li>
                          Brouchure Blockchain Link{" "}
                          <span>
                            <a
                              href={`https://${
                                projectDetails.projects &&
                                projectDetails.projects[0].cid
                              }.ipfs.w3s.link/${
                                projectDetails.projects &&
                                projectDetails.projects[0].brouchure
                              }`}
                              target="_blank"
                            >
                              Link
                            </a>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /tab */}
          </div>
          {/* /tab-content */}
        </div>
        {/* /row */}
      </div>
      <Footer />
    </>
  );
};
export default NFTSingleListing;
