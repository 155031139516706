import Header from "./Components/Header";
import Footer from "./Components/Footer";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
const BrandDetailPage = () => {
  let { brandslug } = useParams();
  const [brandData, setBrandData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `https://saathie.com/api/get/brand/${brandslug}`
      );
      const data = await response.json();

      setBrandData(data);
    };
    fetchData();
  }, []);
  return (
    <>
      <Header />{" "}
      <main>
        <div
          className="hero_single inner_pages author_page jarallax"
          data-jarallax
        >
          <img
            className="jarallax-img"
            src={`https://${
              brandData.brand && brandData.brand.cid
            }.ipfs.w3s.link/${brandData.brand && brandData.brand.banner}`}
            alt=""
          />
          <div
            className="opacity-mask"
            data-opacity-mask="rgba(0, 0, 0, 0.4)"
          />
          <div className="wave hero" />
        </div>
        {/* /hero_single */}
        <div className="container margin_30_40">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6">
              <div className="main_profile">
                <div className="author">
                  <div className="author_thumb veryfied">
                    <i className="bi bi-check" />
                    <figure>
                      <img
                        src={`https://${
                          brandData.brand && brandData.brand.cid
                        }.ipfs.w3s.link/${
                          brandData.brand && brandData.brand.logo
                        }`}
                        alt=""
                        className="lazy"
                        width={100}
                        height={100}
                      />
                    </figure>
                  </div>
                </div>
                <h1>{brandData.brand && brandData.brand.name}</h1>
                <p className="author_number">
                  @{brandData.brand && brandData.brand.slug.toLowerCase()}
                </p>
                <p>
                  {brandData.brand && brandData.brand.description.slice(0, 100)}
                </p>
                <p>
                  <a
                    href={brandData.brand && brandData.brand.website}
                    className="btn_1 full-width mb-2"
                  >
                    Visit
                  </a>
                  <a href="#" className="btn_1 full-width outline">
                    Contact
                  </a>
                </p>

                <small>
                  Joined NFTX{" "}
                  {new Date(
                    brandData.brand && brandData.brand.createdAt
                  ).toDateString()}
                </small>
                <div className="follow_buttons">
                  <ul>
                    <li>
                      <a href={brandData.brand && brandData.brand.instagram}>
                        <i className="bi bi-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href={brandData.brand && brandData.brand.facebook}>
                        <i className="bi bi-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href={brandData.brand && brandData.brand.twitter}>
                        <i className="bi bi-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href={brandData.brand && brandData.brand.website}>
                        <i className="bi bi-globe" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-9 ps-lg-5">
              <div className="tabs_detail">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      id="tab-A"
                      href="#pane-A"
                      className="nav-link active"
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      Details
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      id="tab-B"
                      href="#pane-B"
                      className="nav-link"
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      Created
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      id="tab-C"
                      href="#pane-C"
                      className="nav-link"
                      data-bs-toggle="tab"
                      role="tab"
                    >
                      Images & Videos
                    </a>
                  </li>
                </ul>
                <div className="tab-content" role="tablist">
                  <div
                    id="pane-A"
                    className="card tab-pane fade show active"
                    role="tabpanel"
                  >
                    <div className="card-header" role="tab" id="heading-A">
                      <h5>
                        <a
                          className="collapsed"
                          data-bs-toggle="collapse"
                          href="#collapse-A"
                        >
                          Created
                        </a>
                      </h5>
                    </div>
                    <div id="collapse-A" className="collapse" role="tabpanel">
                      <div className="row mt-lg-5 mt-3">
                        <h4>Brand Description</h4>
                        <p>{brandData.brand && brandData.brand.description}</p>
                        <h4>Brand History</h4>
                        <p>{brandData.brand && brandData.brand.history}</p>
                      </div>

                      {/* /row */}
                    </div>
                  </div>
                  {/* /tab */}
                  <div
                    id="pane-B"
                    className="card tab-pane fade"
                    role="tabpanel"
                  >
                    <div className="card-header" role="tab" id="heading-B">
                      <h5>
                        <a
                          className="collapsed"
                          data-bs-toggle="collapse"
                          href="#collapse-B"
                        >
                          Details
                        </a>
                      </h5>
                    </div>
                    <div id="collapse-B" className="collapse" role="tabpanel">
                      <div className="row mt-lg-5 mt-3">
                        {brandData.brand &&
                          brandData.brand.projects.map((project) => (
                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                              <div className="strip">
                                <figure>
                                  <a
                                    href={`/collection/${project.slug}`}
                                    className="btn_1 modal_popup"
                                  >
                                    Visit Project
                                  </a>
                                  <img
                                    src={`https://${project.cid}.ipfs.w3s.link/${project.logo}`}
                                    className="lazy"
                                    alt=""
                                    width={"auto"}
                                    height={"auto"}
                                  />
                                  <a
                                    href={`/collection/${project.slug}`}
                                    className="strip_info"
                                  >
                                    <div className="item_title">
                                      <h3>{project.name}</h3>
                                    </div>
                                  </a>
                                </figure>
                              </div>
                            </div>
                          ))}
                      </div>
                      {/* /row */}
                    </div>
                  </div>
                  {/* /tab */}
                  <div
                    id="pane-C"
                    className="card tab-pane fade"
                    role="tabpanel"
                  >
                    <div className="card-header" role="tab" id="heading-E">
                      <h5>
                        <a
                          className="collapsed"
                          data-bs-toggle="collapse"
                          href="#collapse-C"
                        >
                          Images & Videos
                        </a>
                      </h5>
                    </div>
                    <div id="collapse-C" className="collapse" role="tabpanel">
                      <div className="follow_list mt-lg-3">
                        <ul>
                          {brandData.brand &&
                            brandData.brand.images.map((image) => (
                              <li>
                                <div className="author">
                                  <div className="">
                                    <figure>
                                      <img
                                        src={`https://${brandData.brand.cid}.ipfs.w3s.link/${image}`}
                                        alt=""
                                        className="lazy"
                                        width={"auto"}
                                        height={"100px"}
                                      />
                                    </figure>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                      {/* /follow_list */}
                    </div>
                  </div>
                  {/* /tab */}
                </div>
                {/* /tab-content */}
              </div>
              {/* /tabs_detail */}
            </div>
          </div>
          {/* /row */}
        </div>
        {/* /container */}
      </main>
      <Footer />
    </>
  );
};
export default BrandDetailPage;
